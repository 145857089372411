import { useState } from "react";
import Layout from "./components/Layout/Layout";
import Calendar from "./components/Calendar";
import CalendarResult from "./components/CalendarResult";
import "./Homepage.scss";

function Homepage() {
  const [birthDate, setBirthDate] = useState(null);
  const [started, setStarded] = useState(0);

  const handleBirthDateChange = (newValue) => {
    setBirthDate(newValue);
    setStarded(2);
  };

  return (
    <Layout className="Homepage p-8 text-center items-center justify-center flex flex-col">
      <h1 className="color-white text-center text-8xl text-white font-sans font-black uppercase mb-16">
        Посчитаем
        <br /> дату рождения
      </h1>

      {started === 0 && (
        <button
          className="start-button bg-slate-800 rounded mb-6 hover:bg-slate-700 transition-all"
          onClick={() => setStarded(1)}
        >Начать</button>
      )}
      
      {started === 1 && 
        <div className="Homepage-calendar">
          <Calendar 
            onValueChange={handleBirthDateChange} 
            onSubmit={handleBirthDateChange} 
          />
        </div>
      }

      {started === 2 && 
        <CalendarResult 
          onClick={() => setStarded(0)}
          number={birthDate} 
        />
      }
    </Layout>
  );
}

export default Homepage;
