export default function CalendarResult({ onClick, number }) {
    const getAllNumbers = (num) => {
        if (num <= 22) return num;
    
        const calcNum = num
          .toString()
          .split("")
          .reduce((acc, curr) => acc + parseInt(curr), 0);
        return getAllNumbers(calcNum);
    };

    const calculateMyNumber = (number) => {
        if (!number) return;
    
        const dateObject = new Date(number);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1;
        const day = dateObject.getDate();
    
        return getAllNumbers(`${day}${month}${year}`);
    };

    return (
        <div className="calendar-result">
            <div className="text-white text-4xl text-center">
                Ваше число
                <br /> <strong>{calculateMyNumber(number)}</strong>
            </div>
            <button
                className="start-button bg-slate-800 rounded mt-6 hover:bg-slate-700 transition-all"
                onClick={onClick}
            >
            Начать заново
            </button>
        </div>
    );
}