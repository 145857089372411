import { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";

export default function Calendar({ onValueChange }) {
  const [value, setValue] = useState({
    startDate: new Date(),
  });

  const handleValueChange = (newValue) => {
    setValue(newValue);
    onValueChange(newValue.startDate);
  };

  return (
    <Datepicker
      placeholder={"Укажите дату рождения"}
      displayFormat={"DD.MM.YYYY"}
      useRange={false}
      asSingle={true}
      value={value}
      onChange={handleValueChange}
      i18n={"ru"}
      onSubmit={handleValueChange}
    />
  );
}
